<template>
  <div>
    <b-card>
      <apexchart type="line" height="330" :options="chartOptions" :series="series">
    </apexchart>
    </b-card>
  </div>
</template>

<script>
import apexchart from "vue-apexcharts";
export default {
  props:["months","values","label"],
  components:{apexchart},
  data() {
    return {
      series: [
        {
          name: "Sales",
          data: []
        }
      ],
      chartOptions: {
        chart: {
          height: 330,
          type: "line",
        },
        xaxis: {
          categories: [
          ]
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "smooth"
        },
        noData: {
          text: "No Data Available Yet",
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: "#000",
            fontSize: "20px",
            fontFamily: "Arial"
          }
        }
      }
    };
  },
  mounted(){
    this.series = [{name:this.label,data:this.values}];
    this.chartOptions = {...this.chartOptions,xaxis:{categories:this.months}};
  }
};
</script>

<style>

</style>